<template>
  <v-card color="grey lighten-4">
    <v-card-title> Páginal inicial </v-card-title>
    <v-card-text>
      <h3 class="mb-3">
        Seja bem-vindo(a) ao painel de Administração Geral do Sistema Guia Tipoalfa!
      </h3>

      <p>Utilize o menu ao seu lado esquerdo para navegar no sistema.</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>
