const state = {
  adPlanFeatures: [],
  adId: null,
};

const mutations = {
  setPlanFeatures(state, features) {
    state.adPlanFeatures = [];
    state.adPlanFeatures = features;
  },
  setAdId(state, adId) {
    state.adId = adId;
  },
};

const actions = {
  setPlanFeatures({ commit }, features) {
    commit("setPlanFeatures", features);
  },
  setAdId({ commit }, adId) {
    commit("setAdId", adId);
  },
};

const getters = {
  validateSpecificFeature: (state) => (featureId) => {
    let featureData = state.adPlanFeatures.find(
      (feature) => feature.id === featureId
    );

    return featureData ? true : false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
