export default [
  {
    path: "/cobrancas",
    name: "Charges",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Charges" */ "../views/Charges/Charges.vue"),
  },

  {
    path: "/cobrancas/:id",
    name: "ChargeDetails",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "ChargeDetails" */ "../views/Charges/ChargeDetails.vue"
      ),
  },
];
