export default [
  {
    path: "/planos",
    name: "Plans",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Plans" */ "../views/Plans/Plans.vue"),
  },
  {
    path: "/planos/cadastrar",
    name: "CreatePlan",
    props: { title: "Cadastrar plano de anúncios", mod: "insert" },
    component: () =>
      import(
        /* webpackChunkName: "CreatePlan" */ "../views/Plans/PlanForm.vue"
      ),
  },
  {
    path: "/planos/:id",
    name: "EditPlan",
    props: { title: "Editar plano de anúncios", mod: "update" },
    component: () =>
      import(/* webpackChunkName: "EditPlan" */ "../views/Plans/PlanForm.vue"),
  },
];
