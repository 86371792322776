export default [
  {
    path: "/clientes",
    name: "Advertisers",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Plans" */ "../views/Advertisers/Advertisers.vue"
      ),
  },
  {
    path: "/clientes/cadastrar",
    name: "RegisterAdvertisers",
    props: { title: "Cadastrar cliente", mod: "insert" },
    component: () =>
      import(
        /* webpackChunkName: "RegisterAdvertisers" */ "../views/Advertisers/AdvertisersForm.vue"
      ),
  },
  {
    path: "/clientes/:id/editar",
    name: "UpdateAdvertisers",
    props: { title: "Editar cliente", mod: "update" },
    component: () =>
      import(
        /* webpackChunkName: "UpdateAdvertisers" */ "../views/Advertisers/AdvertisersForm.vue"
      ),
  },
];
