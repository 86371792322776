export default [
  {
    path: "/intervalos-de-pagamento",
    name: "PaymentTimeIntervals",

    component: () =>
      import(
        /* webpackChunkName: "PaymentTimeIntervals" */ "../views/PaymentTimeIntervals/PaymentTimeIntervals.vue"
      ),
  },
  {
    path: "/intervalos-de-pagamento/cadastrar",
    name: "CreatePaymentTimeInterval",
    props: { title: "Cadastrar intervalos de pagamento", mod: "insert" },
    component: () =>
      import(
        /* webpackChunkName: "CreatePaymentTimeInterval" */ "../views/PaymentTimeIntervals/PaymentTimeIntervalForm.vue"
      ),
  },
  {
    path: "/intervalos-de-pagamento/:id",
    name: "EditPaymentTimeInterval",
    props: { title: "Editar intervalos de pagamento", mod: "update" },
    component: () =>
      import(
        /* webpackChunkName: "EditPaymentTimeInterval" */ "../views/PaymentTimeIntervals/PaymentTimeIntervalForm.vue"
      ),
  },
];
