export default [
  {
    path: "/usuarios/perfis",
    name: "UserProfiles",

    component: () =>
      import(
        /* webpackChunkName: "UserProfiles" */ "../views/UserProfiles/UserProfiles.vue"
      ),
  },
  {
    path: "/usuarios/perfis/cadastrar",
    name: "CreateUserProfile",
    props: { title: "Cadastrar perfil de usuário", mod: "insert" },
    component: () =>
      import(
        /* webpackChunkName: "CreateUserProfile" */ "../views/UserProfiles/UserProfileForm.vue"
      ),
  },
  {
    path: "/usuarios/perfis/:id",
    name: "EditUserProfile",
    props: { title: "Editar perfil de usuário", mod: "update" },
    component: () =>
      import(
        /* webpackChunkName: "EditUserProfile" */ "../views/UserProfiles/UserProfileForm.vue"
      ),
  },
  {
    path: "/usuarios",
    name: "Users",

    component: () =>
      import(/* webpackChunkName: "Users" */ "../views/Users/Users.vue"),
  },
  {
    path: "/usuarios/cadastrar",
    name: "CreateUser",
    props: { title: "Cadastrar usuário", mod: "insert" },
    component: () =>
      import(
        /* webpackChunkName: "CreateUser" */ "../views/Users/UserForm.vue"
      ),
  },
  {
    path: "/usuarios/:id",
    name: "EditUser",
    props: { title: "Editar usuário", mod: "update" },
    component: () =>
      import(/* webpackChunkName: "EditUser" */ "../views/Users/UserForm.vue"),
  },
];
