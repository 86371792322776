import Vue from "vue";
import App from "./App.vue";
import axios from "./plugins/axios";
import moment from "./plugins/moment";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

Vue.use(axios);
Vue.use(moment);
Vue.use(Toast);

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
