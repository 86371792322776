export default [
  {
    path: "/cidades",
    name: "Cities",

    component: () =>
      import(/* webpackChunkName: "Cities" */ "../views/Cities/Cities.vue"),
  },
  {
    path: "/cidades/cadastrar",
    name: "CreateCity",
    props: { title: "Cadastrar cidade", mod: "insert" },
    component: () =>
      import(
        /* webpackChunkName: "CreateCity" */ "../views/Cities/CityForm.vue"
      ),
  },
  {
    path: "/cidades/:id",
    name: "EditCity",
    props: { title: "Editar cidade", mod: "update" },
    component: () =>
      import(/* webpackChunkName: "EditCity" */ "../views/Cities/CityForm.vue"),
  },
];
