export default [
  {
    path: "/denuncias",
    name: "Complaints",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "Charges" */ "../views/Complaints/Complaints.vue"
      ),
  },
];
