<template>
  <v-list>
    <div v-for="item in items" :key="item.title">
      <v-list-group
        :prepend-icon="item.icon"
        :value="true"
        v-if="item.subitems"
      >
        <template v-slot:activator>
          <v-list-item-title class="white--text">{{
            item.title
          }}</v-list-item-title>
        </template>

        <v-list-item
          v-for="subitem in item.subitems"
          :key="subitem.title"
          dark
          :to="{ path: subitem.route }"
          exact
          link
        >
          <v-list-item-content>
            <v-list-item-title>{{ subitem.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item v-else dark :to="{ path: item.route }" exact link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </div>
  </v-list>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        title: "Clientes",
        icon: "mdi-domain",
        route: "/clientes",
      },
      {
        title: "Anúncios",
        icon: "mdi-monitor",
        subitems: [
          {
            title: "Gerenciar anúncios",
            icon: "mdi-monitor",
            route: "/anuncios",
          },
          {
            title: "Categorias de anúncios",
            icon: "",
            route: "/anuncios/categorias",
          },
          {
            title: "Facilidades de anúncios",
            icon: "",
            route: "/anuncios/facilidades",
          },
        ],
      },
      {
        title: "Notícias",
        icon: "mdi-newspaper",
        subitems: [
          {
            title: "Gerenciar notícias",
            icon: "",
            route: "/noticias",
          },
          {
            title: "Categorias de notícias",
            icon: "",
            route: "/noticias/categorias",
          },
        ],
      },
      {
        title: "Planos",
        icon: "mdi-file-multiple",
        route: "/planos",
      },
      {
        title: "Intervalos de pagamentos",
        icon: "mdi-credit-card-clock",
        route: "/intervalos-de-pagamento",
      },
      {
        title: "Usuários",
        icon: "mdi-account",
        route: "/usuarios",
      },
      {
        title: "Perfis de usuário",
        icon: "mdi-file-account",
        route: "/usuarios/perfis",
      },
      {
        title: "Cobranças",
        icon: "mdi-finance",
        route: "/cobrancas",
      },
      {
        title: "Denúncias",
        icon: "mdi-alert-octagon",
        route: "/denuncias",
      },
      {
        title: "Cidades",
        icon: "mdi-city",
        route: "/cidades",
      },
    ],
  }),
};
</script>

<style scoped>
.v-list .v-list-item--active {
  background-color: var(--v-primary-base);
  color: #000;
}
.theme--light.v-icon {
  color: #fff !important;
}
</style>
