export default [
  {
    path: "/noticias",
    name: "News",

    component: () =>
      import(
        /* webpackChunkName: "News" */ "../views/News/News.vue"
      ),
  },
  {
    path: "/noticias/cadastrar",
    name: "CreateNews",
    props: { title: "Cadastrar notícias", mod: "insert" },
    component: () =>
      import(
        /* webpackChunkName: "CreateNews" */ "../views/News/NewsForm.vue"
      ),
  },
  {
    path: "/noticias/:id",
    name: "EditNews",
    props: { title: "Editar notícias", mod: "update" },
    component: () =>
      import(
        /* webpackChunkName: "EditNews" */ "../views/News/NewsForm.vue"
      ),
  },
];
