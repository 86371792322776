import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import plans from "./plans";
import user from "./user";
import charges from "./charges";
import advertisers from "./advertisers";
import adverts from "./adverts";
import adCategories from "./ad_categories";
import newsCategories from "./news_categories";
import news from "./news";
import facilities from "./facilities";
import timeIntervals from "./intervals";
import cities from "./cities";
import complaints from "./complaints";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
  },
  ...plans,
  ...user,
  ...charges,
  ...advertisers,
  ...adverts,
  ...newsCategories,
  ...news,
  ...adCategories,
  ...facilities,
  ...timeIntervals,
  ...cities,
  ...complaints,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
