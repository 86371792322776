export default [
  {
    path: "/anuncios/categorias",
    name: "AdCategories",

    component: () =>
      import(
        /* webpackChunkName: "AdCategories" */ "../views/AdCategories/AdCategories.vue"
      ),
  },
  {
    path: "/anuncios/categorias/cadastrar",
    name: "CreateAdCategory",
    props: { title: "Cadastrar categoria de anúncio", mod: "insert" },
    component: () =>
      import(
        /* webpackChunkName: "CreateAdCategory" */ "../views/AdCategories/AdCategoryForm.vue"
      ),
  },
  {
    path: "/anuncios/categorias/:id",
    name: "EditAdCategory",
    props: { title: "Editar categoria de anúncio", mod: "update" },
    component: () =>
      import(
        /* webpackChunkName: "EditAdCategory" */ "../views/AdCategories/AdCategoryForm.vue"
      ),
  },
];
