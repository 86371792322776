const state = {
  userData: null,
};

const mutations = {
  setUser(state, user) {
    state.userData = user;
  },
  clearUser(state) {
    state.userData = null;
  },
};

const actions = {
  async signin({ commit }, accessData) {
    const response = await this._vm.$axios.post("users/signin-admin", accessData);

    const data = { ...response.data };

    //Salvando no store (memória)
    commit("setUser", data.userData);
  },
  async signout({ commit }) {
    await this._vm.$axios.post("users/signout");

    //Salvando no store (memória)
    commit("setUser", null);
  },
  // async signup({ commit }, userData) {},
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
