export default [
  {
    path: "/noticias/categorias",
    name: "NewsCategories",

    component: () =>
      import(
        /* webpackChunkName: "NewsCategories" */ "../views/NewsCategories/NewsCategories.vue"
      ),
  },
  {
    path: "/noticias/categorias/cadastrar",
    name: "CreateNewsCategory",
    props: { title: "Cadastrar categoria de notícias", mod: "insert" },
    component: () =>
      import(
        /* webpackChunkName: "CreateNewsCategory" */ "../views/NewsCategories/NewsCategoryForm.vue"
      ),
  },
  {
    path: "/noticias/categorias/:id",
    name: "EditNewsCategory",
    props: { title: "Editar categoria de notícias", mod: "update" },
    component: () =>
      import(
        /* webpackChunkName: "EditNewsCategory" */ "../views/NewsCategories/NewsCategoryForm.vue"
      ),
  },
];
