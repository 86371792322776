export default [
  {
    path: "/anuncios/facilidades",
    name: "Facilities",

    component: () =>
      import(
        /* webpackChunkName: "Facilities" */ "../views/Facilities/Facilities.vue"
      ),
  },
  {
    path: "/anuncios/facilidades/cadastrar",
    name: "CreateFacility",
    props: { title: "Cadastrar facilidade de anúncios", mod: "insert" },
    component: () =>
      import(
        /* webpackChunkName: "CreateFacility" */ "../views/Facilities/FacilityForm.vue"
      ),
  },
  {
    path: "/anuncios/facilidades/:id",
    name: "EditFacility",
    props: { title: "Editar facilidade de anúncios", mod: "update" },
    component: () =>
      import(
        /* webpackChunkName: "EditFacility" */ "../views/Facilities/FacilityForm.vue"
      ),
  },
];
