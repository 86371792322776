export default [
  {
    path: "/anuncios",
    name: "Adverts",
    component: () =>
      import(/* webpackChunkName: "Adverts" */ "../views/Adverts/Adverts.vue"),
  },
  {
    path: "/anuncio/cadastrar",
    name: "RegisterAd",
    props: { title: "Cadastrar anúncio", mod: "insert" },
    component: () =>
      import(
        /* webpackChunkName: "RegisterAd" */ "../views/Adverts/CreateAd.vue"
      ),
  },
  {
    path: "/anuncio/:id/completar-cadastro",
    name: "CompleteRegistrationOfAd",
    props: { title: "Completar cadastro de anúncio", mod: "update" },
    component: () =>
      import(
        /* webpackChunkName: "CompleteRegistrationOfAd" */ "../views/Adverts/CompleteRegistration.vue"
      ),
  },
  {
    path: "/anuncio/:id/configurar",
    name: "AdSettings",
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "AdSettings" */ "../views/Adverts/AdSettings.vue"
      ),
  },
];
